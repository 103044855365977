/* @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Orbitron:wght@500&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css");

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

pre {
  white-space: pre-line;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}

.uc {
  text-transform: uppercase;
}

.orbitron {
  font-family: "Orbitron", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  border-bottom: 1px solid #000;
}

.logo {
  width: 175px;
  padding: 15px 20px 0 20px;
}

.logo-wrapper {
}

.steps {
  display: flex;
  flex: 1;
  padding-left: 15px;
}

.step {
  cursor: pointer;
  margin: 15px 0;
  border-right: 1px solid #000;
  text-transform: uppercase;
  font-weight: bold;
  padding: 15px 26px 15px 8px;
  font-size: 12px;
}

.step-active {
}

.step-visited {
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.footer {
  height: 100px;
  border-top: 1px solid #000;
  background: #fff;
  color: #000;
  display: flex;
}

.footer .payment-options {
  padding: 1rem 2rem;
}

.footer .price-summary {
  display: flex;
  align-items: center;
  flex: 2;
  border-right: 1px solid #fff;
  padding: 1rem 3rem;
}

.footer .price-summary .price-text {
  font-size: 32px;
  font-weight: 500;
}

.footer .price-summary .estimate-payment a {
  color: #fff !important;
  font-size: 0.8em;
}

.footer .delivery {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 1rem 3rem;
  justify-content: flex-end;
}

.footer .delivery .text {
  text-align: right;
  font-size: 12px;
}

.button {
  box-shadow: none;
  border-radius: 30px;
  text-align: center;
  padding: 0.4rem 5rem;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.red-button {
  background: #f00;
  color: #fff;
}

.white-button {
  background: #fff;
  color: #000;
  font-size: 14px !important;
  padding: 0.3rem 1rem !important;
}

.black-button {
  background: #000;
  color: #fff;
}

.button.wide {
  padding: 0.5rem 3rem;
  font-size: 20px;
  border: none;
  outline: 0;
}

.button.small {
  padding: 0.3rem 2rem;
  font-size: 14px;
}

.model-name {
  font-size: 32px;
  font-weight: 700;
  margin-left: 10px;
}

.step-content-inner {
  display: flex;
  flex: 1;
}

/* ImageStep */
.step-image-content {
  display: flex;
  flex: 1;
}

.step-image-content .left {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.step-image-content .image {
  display: flex;
  flex: 9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.step-image-content .options-wrapper {
  width: 400px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding: 1rem;
}

.option-group-title {
  margin-top: 10px;
  color: #666;
  padding: 1rem;
}

.option-title {
  font-size: 0.9rem;
}

.option-price {
  font-size: 0.8rem;
  font-weight: bold;
}

.option-description {
  font-size: 10px;
  font-style: italic;
}

.option-radio {
  cursor: pointer;
  align-items: center;
  border: 1px solid #333;
  margin: 10px;
  padding: 10px;
  border-radius: 0 24px;
}

.option-radio .option-title {
}

.option-radio .option-radio-description {
  font-size: 14px;
  margin-top: 5px;
}

.option-radio.option-selected,
.option-radio.option-package {
}

.option-radio-image {
  cursor: pointer;
  display: flex;
}

.option-radio-image.image-top {
  flex-direction: column;
}

.singleImage .options {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.option-group {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.option-checkbox-image {
  cursor: pointer;
  padding: 10px 10px;
}

.step-grid-content {
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
}

.option-group.multiImage .option-group-options {
  padding-top: 0rem;
  display: grid;
  padding: 0 2rem;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.option-group.multi .option-group-options {
  display: grid;
  padding: 5rem;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 15px;
}

.option-checkbox {
  height: 220px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
}

.option-checkbox .option-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.option-checkbox .option-description {
  flex: 1;
}

.option-checkbox .option-price {
  font-size: 1rem;
}

.option-checkbox pre {
  line-height: 1.5;
}

.option-checkbox.option-selected,
.option-checkbox.option-package {
  padding: 8px;
  border: 3px solid #b01813;
}

.option-checkbox.option-selected .option-title,
.option-checkbox.option-selected .option-price,
.option-checkbox.option-package .option-title,
.option-checkbox.option-package .option-price {
  color: #b01813;
}

.option-checkbox .option-title {
  font-size: 1.1rem;
}

.option-checkbox-image .option-inner {
  margin: 15px;
}

.option-checkbox-image .option-image img {
  width: 100%;
}

.image-top .option-image img {
  width: 100%;
}

.option-checkbox-image .option-inner .option-image img {
  padding: 3px;
  width: 100%;
}

.option-checkbox-image.option-selected .option-inner .option-image img,
.option-checkbox-image.option-package .option-inner .option-image img {
  padding: 0;
  border: 5px solid #d3282f;
}
.option-package-name {
  padding: 0;
  color: #d3282f;
}

.option-package {
  opacity: 0.5;
}

.step-summary-content {
  display: flex;
  flex: 1;
}

.step-summary-content .left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.step-summary-content .heading {
  text-align: center;
}

.step-summary-content .heading h1 {
  font-size: 30px;
  margin-bottom: 10px;
}

.step-summary-content .heading h2 {
  font-size: 24px;
  margin-top: 0;
  font-weight: normal;
}

.step-summary-content .buttons {
  text-align: center;
  margin-bottom: 25px;
}

.step-summary-content .image {
  display: flex;
  flex: 9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.step-summary-content .options-wrapper {
  width: 400px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding: 1rem;
  padding-bottom: 50px;
}

.step-summary-content .options-wrapper h2 {
  font-size: 50px;
  margin-top: 0px !important;
  margin-bottom: -20px;
}

.step-summary-content .options-wrapper h3 {
  font-size: 26px;
  margin-bottom: 15px;
}

.standards {
  display: flex;
}

.standards-left {
  margin-right: 15px;
}

.upgrades {
  display: grid;
  grid-template-columns: auto;
}

.upgrades .option {
  padding-bottom: 5px;
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 13px;
  border-bottom: 1px solid #ddd;
}

.upgrades .option .text {
  margin-right: 10px;
  font-weight: bold;
}

.upgrades .option .price {
  display: inline-block;
  color: #f00;
}

.step-payment-content {
  display: flex;
  flex: 1;
}

.step-payment-content .left {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem 2rem;
  overflow: auto;
}

.step-payment-content .heading {
  text-align: center;
}

.step-payment-content .heading h1 {
  font-size: 52px;
  margin-bottom: 10px;
}

.step-payment-content .heading h2 {
  font-size: 40px;
  margin-top: 0;
  font-weight: normal;
}

.step-payment-content .buttons {
  text-align: center;
  margin-bottom: 25px;
}

.step-payment-content .image {
  display: flex;
  flex: 9;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.step-payment-content .options-wrapper {
  width: 400px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding: 1rem;
  padding-bottom: 50px;
}

.step-payment-content .options-wrapper h2 {
  font-size: 50px;
}

.step-payment-content .options-wrapper h3 {
  font-size: 26px;
  margin-bottom: 15px;
}

.step-payment-content .image {
  width: 100%;
}

.step-payment-content .purchase-wrapper {
  font-size: 20px;
  border-top: 1px solid;
  margin-top: 15px;
  padding-top: 15px;
}

.step-payment-content .purchase-wrapper .duetoday {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
}

.step-payment-content .purchase-footer-text {
  font-size: 13px;
  margin-top: 10px;
}

.step-payment-content .purchase-price {
  display: flex;
  justify-content: space-between;
}

.step-summary-content .purchase-wrapper {
  font-size: 20px;
  border-top: 1px solid;
  margin-top: 15px;
  padding-top: 15px;
}

.step-summary-content .purchase-price {
  display: flex;
  justify-content: space-between;
}

.step-summary-content .purchase-price .purchase-price-amount {
  font-weight: bold;
}

.form {
  display: grid;
  grid-template-columns: auto auto;
}

.form .form-item {
  padding: 1rem;
  margin: 0 15px;
}

.form label {
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
}

.form input {
  padding: 0.8rem 1rem;
  border-radius: 4px;
  width: 100%;
  outline: none !important;
  font-size: 16px;
  border: 1px solid #ccc;
}

.form select {
  padding: 0.8rem 1rem;
  border-radius: 4px;
  width: 100%;
  outline: none !important;
  font-size: 16px;
  border: 1px solid #ccc;
  background: #fff;
}

.step-payment-content .left h2 {
  font-size: 32px;
  padding-left: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.step-payment-content .agreement-text {
  padding: 1rem;
  line-height: 2;
  font-size: 12px;
}

.step-complete-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.step-complete-content .complete-content {
  flex: 1;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  width: 900px;
  margin: 0 auto;
}

.step-complete-content .image {
  display: flex;
  flex: 2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.error {
  padding: 1rem;
  background: #b01813;
  color: #fff;
}

.step-content-inner {
  display: none;
}

.flex {
  display: flex;
}

.title {
  display: none;
  padding: 1rem 2rem;
  color: #231f20;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid silver;
  align-items: center;
}

.title svg {
  font-size: 24px;
}

.option-group.multi .option-group-options {
  padding: 2rem;
}

/* add */

/* .singleImage .options {
  display: grid;
  grid-template-columns: 1fr 1fr;
} */

.option-radio-image {
  cursor: pointer;
  padding: 10px 10px;
}

/* .option-radio-image img {
  box-shadow: 0 0 10px 6px #ddd;
  border-radius: 12px;
  width: 100%;
} */

/* .option-radio-image.option-package img,
.option-radio-image.option-selected img {
  padding: 0;
  border: 3px solid #d3282f;
  border-radius: 12px;
} */

.option-title {
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.option-price {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 0.2rem;
}

.model-name {
  text-align: center;
}

.option-group-title {
  color: #000;
}

.option-image {
}

.option-image img {
  margin-right: 10px;
}

.option-title {
  font-weight: bold;
}

.option-description {
  margin-top: 10px;
}

.option-content {
  background: #eee;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.option-price {
  text-align: right;
  margin-top: 15px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.option-selector {
  width: 12px;
  height: 12px;
  padding: 3px;
  border-radius: 100%;
  border: 1px solid;
  display: inline-block;
  content: "";
  margin-left: 10px;
}

.option-selector.selected .tick {
  display: inline-block;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
}

.option-selector.selected .tick {
  background: red;
}

.indicator {
  display: none;
}

.indicator2 {
  margin-right: 10px;
}

.modelSelector__left {
  flex: 1;
  overflow: auto;
}

.modelSelector__right {
  width: 500px;
  border-left: 1px solid #000;
  overflow: auto;
}

.modelSelector__title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 32px;
}

.modelSelector__name {
  text-align: right;
  margin-top: 10px;
}

.modelSelector__items {
  padding: 35px 50px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
}

.modelSelector__item {
  padding: 25px;
  cursor: pointer;
}

.modelSelector__item.selected {
  box-shadow: 0 0 12px 0 #ff000099;
  border-radius: 24px;
}

.modelSelector__item.selected .indicator {
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
}

.modelSelector__image img {
  width: 100%;
}

.modelSelector__footer {
  background: #fff;
  border-top: 1px solid #000;
}

.modelSelector__right {
  padding: 25px;
}

.selectedModel__title {
  margin-top: 20px;
  font-size: 28px;
}

.selectedModel__tech {
  margin-top: 25px;
  margin-bottom: 15px;
}

.selectedModel__subtitle {
  margin-bottom: 15px;
}

.selectedModel__specs {
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
}

.selectedModel__spec {
  margin-bottom: 15px;
}

.selectedModel__spec--key {
  font-weight: bold;
}

.selectedModel__spec--text {
}

.selectedModel__tabs--item {
  margin-bottom: 10px;
}

.selectedModel__tabs--item .tabs-icon {
  margin-left: 10px;
}

.selectedModel__tabs--item.selected .selectedModel__tabs--content {
  display: block;
}

.selectedModel__tabs--title {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 10px;
}

.selectedModel__tabs--content {
  display: none;
  background: #f1f1f1;
  padding: 10px;
}

.selectedModel__tabs--content pre {
  line-height: 1.5;
  font-size: 14px;
}

.modelSelector__footer {
  display: flex;
  justify-content: space-between;
  color: #000;
  align-items: center;
  padding: 0 25px;
}

.modelSelector__button {
  display: inline-block;
  background: red;
  color: #fff;
  text-decoration: none !important;
  border-radius: 6px;
  transform: skew(-30deg);
  margin-right: 30px;
  cursor: pointer;
}

.modelSelector__button.dark {
  background: #000;
}

.modelSelector__button a {
  color: #fff;
  text-decoration: none !important;
  display: inline-block;
  transform: skew(30deg);
  padding: 10px 80px;
  font-weight: bold;
}

.modelSelector__rightLogo {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.option-additional-title {
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.option-additional .option-title {
  margin-top: 0;
  flex: 1;
}

.option-additional .option-content {
  border: 1px solid;
  background: #fff;
  margin: 10px;
  align-items: center;
  flex-direction: row;
  padding: 0;
  padding-right: 10px;
}

.option-additional .option-price {
  margin-top: 0;
}

.option-additional-text {
  width: 100%;
  border: 0;
  outline: none;
  padding: 10px;
}

.mobile-title {
  padding: 20px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
  font-size: 24px;
  box-shadow: 0 5px 5px 0px #00000033;
}

.model-name {
  display: none;
}

.mobile {
  display: none;
}

@media screen and (max-width: 1080px) {
  .step-payment-content .left {
    padding: 2rem 1rem;
  }

  .form input,
  .form select {
    padding: 10px 0;
  }

  .form .form-item {
    padding: 6px;
  }

  .form label {
    margin-bottom: 5px;
    color: #444;
  }

  .step-payment-content .options-wrapper {
    display: none;
  }

  .button.wide {
    padding: 0.4rem 5rem;
  }

  .buttons_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .payment-options {
    font-size: 12px;
    display: inline-block;
    margin-right: 15px;
  }

  .price-display {
    display: inline-block;
  }

  .modelSelector__button.mobile {
    margin-top: 15px;
    margin-right: 0;
  }

  .footer .price-summary .price-text {
    font-size: 16px;
    display: inline-block;
  }

  .mobile {
    display: inline-block;
  }

  .title {
    display: flex;
  }

  .flex {
    display: block;
  }

  .app {
    display: block;
    min-height: 100vh;
    height: auto;
  }

  .content {
    display: block;
    padding-bottom: 100px;
  }

  .steps {
    display: none;
  }

  .header {
    justify-content: center;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .footer .price-summary {
    display: block;
    padding: 1rem;
  }

  .footer .payment-options {
    padding: 0;
  }

  .footer .delivery {
    display: none;
  }

  .footer .estimate-payment {
    display: none;
  }

  .step-content {
    display: block;
  }

  .button.wide {
    margin: 10px;
    display: block;
  }

  .step-content-inner.visible-mobile {
    display: block !important;
  }

  .step-payment-content .left {
    display: block;
  }

  .step-grid-content {
    display: block;
  }

  .option-group .group-optiongroup {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .step-summary-content,
  .step-payment-content {
    display: block;
  }

  .standards {
    display: block;
  }

  .upgrades {
    grid-template-columns: auto;
  }

  .form {
    grid-template-columns: auto;
  }

  .step-summary-content .options-wrapper,
  .step-payment-content .options-wrapper {
    width: auto;
  }

  .option-group.multi .option-group-options {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .option-group.multiImage .option-group-options {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .standards,
  .step-payment-content,
  .step-summary-content {
    padding: 0.5rem;
  }

  .step-complete-content {
    display: block;
  }

  .modelSelector__name {
    text-align: center;
    font-size: 26px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }

  .modelSelector__items {
    grid-template-columns: auto auto;
  }

  .modelSelector__right {
    width: auto;
  }

  .selectedModel__specs {
    grid-template-columns: auto auto;
  }

  .selectedModel__tabs--item .tabs-icon {
    float: left;
    margin-left: 0;
    margin-right: 10px;
  }

  .selectedModel__tabs--title {
    font-size: 14px;
  }

  .selectedModel__tabs--content pre {
    font-size: 13px;
  }

  .option-image img {
    max-width: 100%;
  }

  .step-complete-content .complete-content {
    width: auto;
    padding: 25px;
  }

  .step-complete-content .complete-content .modelSelector__button {
    margin-top: 25px;
  }

  .step-complete-content .complete-content .modelSelector__button a {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 768px) {
  .modelSelector__items {
    grid-template-columns: auto;
  }
}
